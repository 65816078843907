import * as ACTION_TYPES from './serviceActionTypes';

export const serviceReducerInitialState = {
	listServices:{
		hasError: false,
		isFetching: false,
		error: undefined,
		optionsSelects:[],
		data:[],
	}
};

export const serviceReducer = (state = serviceReducerInitialState, action) => {
	switch (action.type) {

		// Service actions
		case ACTION_TYPES.SERVICE_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				listServices:{
					...state.listServices,
					isFetching: true,
					hasError: false,
					error: undefined,
				}
			};
		}
		case ACTION_TYPES.SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;
		;
			return {
				...state,
				listServices:{
					...state.listServices,
					isFetching: false,
					hasError: false,
					error: undefined,
					data:data?.services
				}
			};
		}
		case ACTION_TYPES.SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listServices:{
					...state.listServices,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SERVICE_DATA: {
			return {
				...serviceReducerInitialState
			};
		}
		case ACTION_TYPES.SERVICE_REQUEST_SELECT: {
			const {data} = state.listServices;
			const optionsSelects=data.map((item)=>{
        // console.log(item.price);
				return {value:item.id,label:`${item.name}`,price:item.price}
			});
			return {
				...state,
				listServices:{
					...state.listServices,
					optionsSelects
				}
			};
		}

		// END Service actions
		default: {
			return state;
		}
	}
};

export default serviceReducer;

export const getServiceReducer = state => state.serviceReducer;

export const getListServicesData = state => getServiceReducer(state)?.listServices?.data;

export const getListServicesOptionsSelects = state => getServiceReducer(state)?.listServices?.optionsSelects;

export const getIsFetching = state => getServiceReducer(state)?.listServices?.isFetching;

