import { CButton } from '@coreui/react'
import React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
const ModalTerms = ({ onHide, ...rest }) => {
  return (
    <Modal size="lg" {...rest} onHide={onHide} backdrop="static" keyboard={false}>
      <ModalHeader className="text-center fs-5 fw-bolder">
        <p>Términos y Condiciones del Pago de Aranceles para la Revisión Técnica Obligatoria (RTO)</p>
      </ModalHeader>

      <ModalBody>
        <ul className="list-unstyled px-3">
          <li className="mt-2 text-justify">
          La <strong> Revisión Técnica Obligatoria (RTO) </strong>es un procedimiento esencial que
          permite verificar si un vehículo cumple con las condiciones mínimas necesarias
          para garantizar la seguridad del conductor, pasajeros y usuarios de la vía
          pública.
          </li>
          <li className="mt-2"><strong>Condiciones</strong> Por Plataforma Digital: Respecto a las Plantas de Revisión:</li>
          <li className="mt-2 text-justify">
          Los aranceles publicados en la página web <a href="https://vtvmisiones.com">https://vtvmisiones.com</a> son aplicables a
          todas las plantas de verificación técnica vehicular o motovehicular situadas en la
          jurisdicción Provincial. Estos aranceles están de acuerdo con la categoría de cada
          vehículo, conforme lo establecido por la <strong>Ley de Tránsito Nº 24.449, artículo 34</strong>.
          </li>
          <li className="mt-2">
          Acerca de los <strong>Cobros</strong>:
          </li>
          <li className="mt-2 text-justify" >
          a) Cada planta de verificación ofrece la posibilidad de realizar <strong>pagos en línea</strong>.
          Por lo tanto, al solicitar un turno, el usuario podrá optar por el pago online
          utilizando los métodos establecidos: tarjetas de crédito, tarjetas de débito,
          home banking, botones de pago, enlaces de pago, agentes de cobro y códigos
          QR.
          </li>
          <li className="mt-2 text-justify">
          b) Si al presentarse en la planta se detecta que la categoría del vehículo no
          coincide con la abonada mediante la plataforma en línea, se informará al
          usuario antes de proceder con la revisión técnica.
          </li>
          <li className="mt-2 text-justify">
          c) En el evento de que el usuario haya pagado un monto <strong>superior</strong> al que
          corresponde por categoría del vehículo, la <strong>devolución del excedente</strong> se
          efectuará de manera inmediata o dentro de un plazo máximo de <strong>treinta (30)
          días corridos</strong>. Este período permite verificar y corregir cualquier error en la
          clasificación y carga en el sistema.
          </li>
          <li className="mt-2 text-justify">
          d) Si el usuario efectuó un pago <strong>inferior</strong> al requerido según la categoría del
          vehículo, podrá abonar la diferencia en la planta utilizando los métodos de pago
          disponibles. Esto es necesario para rectificar el error y proceder con los trámites
          correspondientes.
          </li>
          <li className="mt-2 text-justify">
          <strong>Contacto</strong>: Ante dudas, consultas o sugerencias, puede comunicarse con la <strong>Dirección de Verificación del Automotor de la Policía de Misiones</strong> al
          teléfono <strong>0376-4480918</strong> o personalmente en la <strong>calle Florentino Ameghino N°
          2497</strong>.
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <CButton onClick={() => onHide()}>Cerrar</CButton>
      </ModalFooter>
    </Modal>
  )
}
ModalTerms.propTypes = {
  onHide: PropTypes.func.isRequired,
}
export default ModalTerms
