import * as ACTION_TYPES from './plantActionTypes';

export const plantReducerInitialState = {
	listPlants:{
		hasError: false,
		isFetching: false,
		error: undefined,
		optionsSelects:[],
		data:[],
	}
};

export const plantReducer = (state = plantReducerInitialState, action) => {
	switch (action.type) {

		// Plant actions
		case ACTION_TYPES.PLANT_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				listPlants:{
					...state.listPlants,
					isFetching: true,
					hasError: false,
					error: undefined,
				}
			};
		}
		case ACTION_TYPES.PLANT_REQUEST_SUCCESS: {
			const { data} = action.payload;

			return {
				...state,
				listPlants:{
					...state.listPlants,
					isFetching: false,
					hasError: false,
					error: undefined,
					data:data
				}
			};
		}
		case ACTION_TYPES.PLANT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listPlants:{
					...state.listPlants,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_PLANT_DATA: {
			return {
				...plantReducerInitialState
			};
		}
		case ACTION_TYPES.PLANT_REQUEST_SELECT: {
			const {data} = state.listPlants;
			const optionsSelects=data?.map((item)=>{
				return {value:item.id,label:`${item.name} (${item?.locality?.name})`}
			});
			return {
				...state,
				listPlants:{
					...state.listPlants,
					optionsSelects
				}
			};
		}

		// END Plant actions
		default: {
			return state;
		}
	}
};

export default plantReducer;

export const getPlantReducer = state => state.plantReducer;

export const getListPlantsData = state => getPlantReducer(state)?.listPlants.data;

export const getListPlantsOptionsSelects = state => getPlantReducer(state)?.listPlants?.optionsSelects;

export const getIsFetching = state => getPlantReducer(state)?.listPlants?.isFetching;

