import * as ACTION_TYPES from './reviewTypeActionTypes'

export const reviewTypeReducerInitialState = {
  listReviewType: {
    hasError: false,
    isFetching: false,
    error: undefined,
    optionsSelects: [],
    data: [],
  },
}

export const reviewTypeReducer = (state = reviewTypeReducerInitialState, action) => {
  switch (action.type) {
    // Service actions
    case ACTION_TYPES.REVIEW_TYPE_REQUEST: {
      const { params } = action.payload
      return {
        ...state,
        listReviewType: {
          ...state.listReviewType,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.REVIEW_TYPE_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        listReviewType: {
          ...state.listReviewType,
          isFetching: false,
          hasError: false,
          error: undefined,
          data,
        },
      }
    }
    case ACTION_TYPES.REVIEW_TYPE_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        listReviewType: {
          ...state.listReviewType,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }

    case ACTION_TYPES.REVIEW_TYPE_REQUEST_SELECT: {
      const { data } = state.listReviewType
      const optionsSelects = data.map((item) => {
        // console.log(item.price);
        return { value: item.id, label: `${item.name}`, price: item.price }
      })
      return {
        ...state,
        listReviewType: {
          ...state.listReviewType,
          optionsSelects,
        },
      }
    }

    // END Service actions
    default: {
      return state
    }
  }
}

export default reviewTypeReducer

export const getReviewTypeReducer = (state) => state.reviewTypeReducer
console.log('getReviewTypeReducer,', getReviewTypeReducer)
export const getListReviewTypeData = (state) => getReviewTypeReducer(state)?.listReviewType?.data

export const getlistReviewTypeOptionsSelects = (state) =>
  getReviewTypeReducer(state)?.listReviewType?.optionsSelects

export const getIsFetching = (state) => getReviewTypeReducer(state)?.listReviewType?.isFetching
