
export const TURN_REQUEST = 'TURN_REQUEST';
export const TURN_REQUEST_SUCCESS = 'TURN_REQUEST_SUCCESS';
export const TURN_ASSIGNED_SUCCESS = 'TURN_ASSIGNED_SUCCESS';
export const TURN_REQUEST_FAILURE = 'TURN_REQUEST_FAILURE';
export const CLEAR_TURN_DATA = 'CLEAR_TURN_DATA';
export const CLEAR_TURN_PARAMS = 'CLEAR_TURN_PARAMS';
export const TURN_REQUEST_SELECT = 'TURN_REQUEST_SELECT';

//get TURN_AVAILABLE_REQUEST
export const TURN_AVAILABLE_REQUEST = 'TURN_AVAILABLE_REQUEST';
export const TURN_AVAILABLE_REQUEST_SUCCESS = 'TURN_AVAILABLE_REQUEST_SUCCESS';
export const TURN_AVAILABLE_REQUEST_FAILURE = 'TURN_AVAILABLE_REQUEST_FAILURE';
export const CLEAR_TURN_AVAILABLE_DATA = 'CLEAR_TURN_AVAILABLE_DATA';

//get hours of a days
export const TURN_HOURS_REQUEST = 'TURN_HOURS_REQUEST';
export const TURN_HOURS_REQUEST_SUCCESS = 'TURN_HOURS_REQUEST_SUCCESS';
export const TURN_HOURS_REQUEST_FAILURE = 'TURN_HOURS_REQUEST_FAILURE';
export const CLEAR_TURN_HOURS_DATA = 'CLEAR_TURN_HOURS_DATA';

//find turn
export const TURN_FIND_REQUEST = 'TURN_FIND_REQUEST';
export const TURN_FIND_REQUEST_SUCCESS = 'TURN_FIND_REQUEST_SUCCESS';
export const TURN_FIND_REQUEST_FAILURE = 'TURN_FIND_REQUEST_FAILURE';
export const CLEAR_TURN_FIND_DATA = 'CLEAR_TURN_HOURS_DATA';
