import * as ACTION_TYPES from './paymentActionTypes'

export const paymentReducerInitialState = {
  postHelipagos: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: [],
  },
  getMacroPagos: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: [],
  },
  getStatusTokenHelipagos: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: false,
  },
  urlPayment: '',
}

export const paymentReducer = (state = paymentReducerInitialState, action) => {
  switch (action.type) {
    //START Payment Helipago actions
    case ACTION_TYPES.HELIPAGOS_REQUEST: {
      return {
        ...state,
        postHelipagos: {
          ...state.postHelipagos,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.HELIPAGOS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        postHelipagos: {
          ...state.postHelipagos,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
        urlPayment: action.payload?.checkout_url,
      }
    }
    case ACTION_TYPES.HELIPAGOS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        postHelipagos: {
          ...state.postHelipagos,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_HELIPAGOS_REQUEST: {
      return {
        ...paymentReducerInitialState,
      }
    }
    case ACTION_TYPES.HELIPAGOS_TOKEN_STATUS_REQUEST: {
      return {
        ...state,
        getStatusTokenHelipagos: {
          ...state.getStatusTokenHelipagos,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.HELIPAGOS_TOKEN_STATUS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        getStatusTokenHelipagos: {
          ...state.getStatusTokenHelipagos,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
        urlPayment: action.payload?.checkout_url,
      }
    }
    case ACTION_TYPES.HELIPAGOS_TOKEN_STATUS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        getStatusTokenHelipagos: {
          ...state.getStatusTokenHelipagos,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }

    //END Payment Helipago actions

    //START Payment macro pagos
    case ACTION_TYPES.MACRO_CLICK_PAGOS_REQUEST: {
      return {
        ...state,
        getMacroPagos: {
          ...state.getMacroPagos,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.MACRO_CLICK_PAGOS_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        getMacroPagos: {
          ...state.getMacroPagos,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
      }
    }
    case ACTION_TYPES.MACRO_CLICK_PAGOS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        getMacroPagos: {
          ...state.getMacroPagos,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_MACRO_CLICK_PAGOS_REQUEST: {
      return {
        ...paymentReducerInitialState,
      }
    }

    //END Payment macro pagos

    default: {
      return state
    }
  }
}

export default paymentReducer

export const getPaymentReducer = (state) => state.paymentReducer
//helipagos
export const getpostHelipagosData = (state) => getPaymentReducer(state)?.postHelipagos.data
export const getIsFetchingHelipagos = (state) => getPaymentReducer(state)?.postHelipagos?.isFetching

export const getHelipagosTokenStatusData = (state) =>
  getPaymentReducer(state)?.getStatusTokenHelipagos?.data
export const getIsFetchingTokenStatusHelipagos = (state) =>
  getPaymentReducer(state)?.getStatusTokenHelipagos?.isFetching

//macro pagos
export const getMacroPagosData = (state) => getPaymentReducer(state)?.getMacroPagos?.data
export const getIsFetchingMacroPagos = (state) =>
  getPaymentReducer(state)?.getMacroPagos?.isFetching

//global
export const getURLPayment = (state) => getPaymentReducer(state).urlPayment
