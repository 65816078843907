//helipagos
export const HELIPAGOS_REQUEST = 'HELIPAGOS_REQUEST';
export const HELIPAGOS_REQUEST_SUCCESS = 'HELIPAGOS_REQUEST_SUCCESS';
export const HELIPAGOS_REQUEST_FAILURE = 'HELIPAGOS_REQUEST_FAILURE';
export const CLEAR_HELIPAGOS_REQUEST = 'CLEAR_HELIPAGOS_REQUEST';

export const HELIPAGOS_TOKEN_STATUS_REQUEST = 'HELIPAGOS_TOKEN_STATUS_REQUEST'
export const HELIPAGOS_TOKEN_STATUS_REQUEST_SUCCESS = 'HELIPAGOS_TOKEN_STATUS_REQUEST_SUCCESS'
export const HELIPAGOS_TOKEN_STATUS_REQUEST_FAILURE = 'HELIPAGOS_TOKEN_STATUS_REQUEST_FAILURE'

//macro click de pagos
export const MACRO_CLICK_PAGOS_REQUEST = 'MACRO_CLICK_PAGOS_REQUEST';
export const MACRO_CLICK_PAGOS_REQUEST_SUCCESS = 'MACRO_CLICK_PAGOS_REQUEST_SUCCESS';
export const MACRO_CLICK_PAGOS_REQUEST_FAILURE = 'MACRO_CLICK_PAGOS_REQUEST_FAILURE';
export const CLEAR_MACRO_CLICK_PAGOS_REQUEST = 'CLEAR_MACRO_CLICK_PAGOS_REQUEST';