import * as ACTION_TYPES from './globalDataActionTypes'

export const globalDataReducerInitialState = {
  sidebarShow: true,
  downloadManual: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: [],
  },
}

export const globalDataReducer = (state = globalDataReducerInitialState, action) => {
  switch (action.type) {
    // sidebar Show
    case ACTION_TYPES.SET_SIDEBAR_SHOW: {
      const { data } = action.payload
      return {
        ...state,
        sidebarShow: data,
      }
    }
    //START download manual
    case ACTION_TYPES.MANUAL_REQUEST: {
      return {
        ...state,
        downloadManual: {
          ...state.downloadManual,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.MANUAL_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        downloadManual: {
          ...state.downloadManual,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
      }
    }
    case ACTION_TYPES.MANUAL_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        downloadManual: {
          ...state.downloadManual,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_MANUAL_REQUEST: {
      return {
        ...globalDataReducerInitialState,
      }
    }

    default: {
      return state
    }
  }
}

export const getGlobalDataReducer = (state) => state.globalDataReducer

export const getSideBarShow = (state) => getGlobalDataReducer(state)?.sidebarShow

//get turns available
export const getDownloadManual = (state) => getGlobalDataReducer(state)?.downloadManual
export const getDownloadManualData = (state) => getDownloadManual(state)?.data
export const getDownloadManualIsFetching = (state) => getDownloadManual(state)?.isFetching
