import * as ACTION_TYPES from './turnActionTypes'

export const turnReducerInitialState = {
  postTurns: {
    hasError: false,
    isFetching: false,
    turnAssigned: false,
    error: undefined,
    optionsSelects: [],
    data: [],
  },
  turnsAvailable: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: [],
  },
  turnsHours: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: null,
  },
  turnFind: {
    hasError: false,
    isFetching: false,
    error: undefined,
    data: null,
  },
}

export const turnReducer = (state = turnReducerInitialState, action) => {
  switch (action.type) {
    // Turn actions
    case ACTION_TYPES.TURN_REQUEST: {
      // const { params } = action.payload;
      return {
        ...state,
        postTurns: {
          ...state.postTurns,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.TURN_REQUEST_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        postTurns: {
          ...state.postTurns,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
      }
    }
    case ACTION_TYPES.TURN_ASSIGNED_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        postTurns: {
          ...state.postTurns,
          isFetching: false,
          turnAssigned: data,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.TURN_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        postTurns: {
          ...state.postTurns,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURN_DATA: {
      return {
        postTurns: turnReducerInitialState?.postTurns,
      }
    }
    case ACTION_TYPES.TURN_REQUEST_SELECT: {
      const { data } = state.postTurns
      const optionsSelects = data.map((item) => {
        return { value: item.id, label: `${item.name} ($${item.price})` }
      })
      return {
        ...state,
        postTurns: {
          ...state.postTurns,
          optionsSelects,
        },
      }
    }

    //try get turns available
    case ACTION_TYPES.TURN_AVAILABLE_REQUEST: {
      return {
        ...state,
        turnsAvailable: {
          ...state.turnsAvailable,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }
    case ACTION_TYPES.TURN_AVAILABLE_REQUEST_SUCCESS: {
      const { data } = action.payload
      const newData = data?.map((element) => {
        element.start_time = new Date(element?.date + 'T' + element.start_time)
        element.end_time = new Date(element?.date + 'T' + element.end_time)

        return element
      })
      return {
        ...state,
        turnsAvailable: {
          ...state.turnsAvailable,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: newData,
        },
      }
    }
    case ACTION_TYPES.TURN_AVAILABLE_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        turnsAvailable: {
          ...state.turnsAvailable,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURN_AVAILABLE_DATA: {
      return {
        ...state,
        turnsAvailable: turnReducerInitialState?.turnsAvailable,
      }
    }
    // END try get turns available
    //try get turns available
    case ACTION_TYPES.TURN_HOURS_REQUEST: {
      return {
        ...state,
        turnsHours: {
          ...state.turnsHours,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }

    case ACTION_TYPES.TURN_HOURS_REQUEST_SUCCESS: {
      const { data } = action.payload
      const newData = data?.map((element) => {
        element.start_time = new Date(element.start_time)
        element.end_time = new Date(element.end_time)
        return element
      })
      return {
        ...state,
        turnsAvailable: {
          ...state.turnsAvailable,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: state?.turnsAvailable?.data ? state.turnsAvailable.data.concat(newData) : newData,
        },
      }
    }
    case ACTION_TYPES.TURN_HOURS_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        turnsHours: {
          ...state.turnsHours,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURN_HOURS_DATA: {
      return {
        ...state,
        turnsHours: turnReducerInitialState?.turnsHours,
      }
    }
    // END try get turns available

    //START find turn
    case ACTION_TYPES.TURN_FIND_REQUEST: {
      return {
        ...state,
        turnFind: {
          ...state.turnFind,
          isFetching: true,
          hasError: false,
          error: undefined,
        },
      }
    }

    case ACTION_TYPES.TURN_FIND_REQUEST_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        turnFind: {
          ...state.turnFind,
          isFetching: false,
          hasError: false,
          error: undefined,
          data: data,
        },
      }
    }
    case ACTION_TYPES.TURN_FIND_REQUEST_FAILURE: {
      const { error } = action.payload
      return {
        ...state,
        turnFind: {
          ...state.turnFind,
          isFetching: false,
          hasError: true,
          error,
        },
      }
    }
    case ACTION_TYPES.CLEAR_TURN_FIND_DATA: {
      return {
        ...state,
        turnFind: turnReducerInitialState?.turnFind,
      }
    }
    // END find turn

    default: {
      return state
    }
  }
}

export default turnReducer

export const getTurnReducer = (state) => state.turnReducer

export const getpostTurnsData = (state) => getTurnReducer(state)?.postTurns.data
export const getpostTurnsOptionsSelects = (state) =>
  getTurnReducer(state)?.postTurns?.optionsSelects
export const getIsFetching = (state) => getTurnReducer(state)?.postTurns?.isFetching
export const getAssigned = (state) => getTurnReducer(state)?.postTurns?.turnAssigned

//get turns available
export const getTurnsAvailable = (state) => getTurnReducer(state)?.turnsAvailable
export const getTurnsAvailableData = (state) => getTurnsAvailable(state)?.data
export const getTurnsAvailableDataFetching = (state) => getTurnsAvailable(state)?.isFetching

//get turns available
export const getTurnHours = (state) => getTurnReducer(state)?.turnsHours
export const getTurnHoursData = (state) => getTurnHours(state)?.data
export const getTurnHoursDataFetching = (state) => getTurnHours(state)?.isFetching

//get turns available
export const getTurnFind = (state) => getTurnReducer(state)?.turnFind
export const getTurnFindData = (state) => getTurnFind(state)?.data
export const getTurnFindDataFetching = (state) => getTurnFind(state)?.isFetching
